input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input {
    -moz-appearance: textfield;
}

input {
    margin-left: 2px;
    text-align: right;
}

/* Define medium grey theme colors */
:root {
    --background-color: #2e2e2e;       /* Medium grey background */
    --text-color: #e0e0e0;             /* Light grey text */
    --link-color: #bb86fc;             /* Light purple links */
    --input-background-color: #3d3d3d; /* Slightly lighter grey for inputs */
    --input-border-color: #555555;     /* Border color for inputs */
    --button-bg-color: #4a4a4a;        /* Medium grey for buttons */
    --button-text-color: #ffffff;      /* White text on buttons */
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  a {
    color: var(--link-color);
  }
  
  button {
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  /* Style for input fields */
  input[type="number"] {
    background-color: var(--input-background-color);
    color: var(--text-color);
    border: 1px solid var(--input-border-color);
    padding: 10px;
    border-radius: 4px;
  }
  
  input[type="number"]::placeholder {
    color: #888888;  /* Light placeholder text */
  }
  
  /* Change input outline on focus */
  input[type="number"]:focus {
    outline: none;
    border-color: #bb86fc;  /* Highlight color on focus */
  }
  
  /* Style headers, footers, etc., to match medium grey */
  header, footer {
    background-color: #3a3a3a;
    color: var(--text-color);
    padding: 20px;
  }